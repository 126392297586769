import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="40"
      height="45"
      viewBox="0 0 40 45"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path d="M20.0142 9.85836L20.2266 57.2805L12.4504 54.7592L12.3654 17.762C12.3654 17.5637 12.4929 17.2521 12.7479 16.8272C13.0312 16.3739 13.6402 15.7224 14.5751 14.8725L20.0142 9.85836ZM16.8697 0C18.3428 0.481586 19.915 0.991502 21.5864 1.52975C23.2578 2.06799 24.8725 2.56374 26.4306 3.017C28.017 3.47025 29.4618 3.85269 30.7649 4.16431C32.0963 4.47592 33.1445 4.63173 33.9093 4.63173C34.4193 4.63173 34.8867 4.58924 35.3116 4.50425C35.7365 4.39093 36.1615 4.22096 36.5864 3.99433C37.0113 3.7677 37.4646 3.47025 37.9462 3.10198C38.4278 2.73371 38.9802 2.26629 39.6034 1.69972C37.8187 3.48442 36.2323 4.94334 44.8442 8.07649C33.4561 6.20963 42.238 8.10198 41.1898 8.75354C30.1416 9.37677 49.2351 9.8017 48.4703 10.0283C27.7054 10.255 27.0255 10.3683 26.4306 10.3683C25.6091 10.3683 24.5751 10.2408 23.3286 9.98584C22.1105 9.70255 20.779 9.34844 19.3343 8.92351C17.9178 8.49858 16.4448 8.017 14.915 7.47875C13.4136 6.91218 0.983 6.34561 0.6232 3.77904L16.8697 0ZM36.8414 27.9178C36.5864 29.0793 36.1756 30.3116 35.6091" />
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
